import React from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  , Grid,
  Card,
  CardContent,
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactPlayer from 'react-player'
import { image_complete_card ,image_hide  } from "../assets";
import { TabPanel ,Breadcrumbs ,ButtonComponent ,CongratulationsModal ,TabIcon} from "../../../../components/src/SidebarMenu.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: any
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// Customizable Area End

import AssessmentQuestionsController, {
  Props,
} from "./AssessmentQuestionsController";
import { AnswerI, Question } from "../IAssessment";

export default class AssessmentQuestions extends AssessmentQuestionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  LessonvideoContent = ({ item }: { item: any }) => {
    return (
      <React.Fragment>
        <Box className="page-wrapper-container">
          <Box className="page-wrapper">
            <Breadcrumbs
              lessons_all_data={this.state.lessons_all_data}
              navigateToAssessmentDetail={this.navigateToAssessmentDetail}
              navigateToAssessmentTestScreen = {this.navigateToAssessmentTestScreen}
            />
            <Grid item>
              <Typography className="mainHeading">{item.attributes.lesson_title}</Typography>
            </Grid>
            <Card className="courseCardStyle"
              data-test-id="course_card">
              <ReactPlayer 
                className="course-video"
                url={item.attributes.study_material}
                controls={true}
                width='100%'
                height='460px'
                playing={true}
                light={<img src="https://static.vecteezy.com/system/resources/thumbnails/010/702/131/small/cover-thumbnail-for-edited-several-theme-right-pastel-and-2-tone-free-photo.jpg" alt="Play" className="tab-img" />}
              />
              <CardContent style={{ padding: '0' }}>
                <Box className="cardContentMainBox">
                  <Typography className="cardDescription">{item.attributes.description}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <ButtonComponent
            state = {this.state}
            markAsComplete={item.attributes.completed}
            handlePrevious = {this.handlePrevious}
            handleMarkAsComplete = {this.handleMarkAsComplete}
            handleNext = {this.handleNext}
            handleOpencongratulationsModel = {this.handleOpencongratulationsModel}
          />
        </Box>
      </React.Fragment >
    )
  };

  CustomTabContent = ({ item }: { item: any }) => {
    const { selectedAnswers } = this.state;
    return (
      <React.Fragment>
        <Box className="page-wrapper-container">
          <Box className="page-wrapper">
            <Breadcrumbs
              lessons_all_data={this.state.lessons_all_data}
              navigateToAssessmentDetail={this.navigateToAssessmentDetail}
              navigateToAssessmentTestScreen={this.navigateToAssessmentTestScreen}
            />
            <Grid item>
              <Typography className="mainHeading">{item.attributes.lesson_title}</Typography>
            </Grid>

            {item.attributes && item.attributes.questions && item.attributes.questions.data.map((question: any, index: any) => {
              const { question_option_image_a, question_option_image_b, question_option_image_c, question_option_image_d, question_option_image_name_a, question_option_image_name_b, question_option_image_name_c, question_option_image_name_d, answer_option_a, answer_option_b, answer_option_c, answer_option_d } = question.attributes;
              return (
                <Box className="quiz-card" key={index}>
                  <Typography className="questiontext">{question.attributes.question}</Typography>
                  {(question_option_image_a.length || question_option_image_b.length || question_option_image_c.length || question_option_image_d.length) ? <Grid container spacing={3} className="">

                    {question_option_image_a.length ? <Grid item md={3} className = "marginBottom">
                      <Box className="quiz-card-img">
                        <img src={question_option_image_a} className="quiz-img" alt="no image" />
                        <Typography className="Quiz_options">{question_option_image_name_a}</Typography>
                      </Box>
                    </Grid> : ""}

                    {question_option_image_b.length ? <Grid item md={3} className = "marginBottom">
                      <Box className="quiz-card-img">
                        <img src={question_option_image_b} alt="" className="quiz-img" />
                        <Typography className="Quiz_options">{question_option_image_name_b}</Typography>
                      </Box>
                    </Grid> : ""}

                    {question_option_image_c.length ? <Grid item md={3} className = "marginBottom">
                      <Box className="quiz-card-img">
                        <img src={question_option_image_c} alt="" className="quiz-img" />
                        <Typography className="Quiz_options">{question_option_image_name_c}</Typography>
                      </Box>
                    </Grid> : ""}

                    {question_option_image_d.length ? <Grid item md={3} className = "marginBottom">
                      <Box className="quiz-card-img">
                        <img src={question_option_image_d} alt="" className="quiz-img" />
                        <Typography className="Quiz_options">{question_option_image_name_d}</Typography>
                      </Box>
                    </Grid> : ""}
                  </Grid> : ""}

                  {[
                    { option: answer_option_a, key: "a" },
                    { option: answer_option_b, key: "b" },
                    { option: answer_option_c, key: "c" },
                    { option: answer_option_d, key: "d" },
                  ].map(
                    (answerOption) =>
                      answerOption.option && (
                        <Box className={this.state.selectedAnswers[question.attributes.id] === answerOption.option ? "quiz-radio-option-checked" : "quiz-radio-option"} key={answerOption.key}>
                          <input
                            id={`option-${answerOption.key}-${question.attributes.id}`}
                            name={`question-${question.attributes.id}`}
                            type="radio"
                            value={answerOption.option}
                            checked={
                              selectedAnswers[question.attributes.id] === answerOption.option
                            }
                            onChange={() =>
                              this.handleCheckboxChange({
                                questionId: question.attributes.id,
                                selectedOption: answerOption.option,
                                courseId: question.attributes.course_id,
                                quizId: item.attributes.id,
                                selectedOptionKey: answerOption.key
                              })
                            }
                          />
                          <label htmlFor={`option-${answerOption.key}-${question.attributes.id}`}>
                            {answerOption.option}
                          </label>
                        </Box>
                      )
                  )}
                </Box>
              )
            })}
          </Box>
          <ButtonComponent
            state = {this.state}
            markAsComplete={item.attributes.completed}
            handlePrevious = {this.handlePrevious}
            handleMarkAsComplete = {this.handleMarkAsComplete}
            handleNext = {this.handleNext}
            type={item.type}
            handleOpencongratulationsModel = {this.handleOpencongratulationsModel}
          />
        </Box>
      </React.Fragment >
    );
  };

  LessondocumentContent = ({ item }: { item: any }) => (
    <Box className="page-wrapper-container">
      <Box className="page-wrapper">
        <Breadcrumbs
          lessons_all_data={this.state.lessons_all_data}
          navigateToAssessmentDetail={this.navigateToAssessmentDetail}
          navigateToAssessmentTestScreen={this.navigateToAssessmentTestScreen}
        />
        <Grid item>
          <Typography className="mainHeading">{item.attributes.lesson_title}</Typography>
        </Grid>
        <Box>
          <Card className="courseCardStyle">
            <embed
              src={item.attributes.study_material}
              type="application/pdf"
              width="100%"
              height="600px"
            />
            <CardContent style={{ padding: '0', marginTop: "18px" }}>
              <Box className="cardContentMainBox">
                <Typography className="cardDescription">{item.attributes.description}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <ButtonComponent
        state={this.state}
        markAsComplete={item.attributes.completed}
        handlePrevious={this.handlePrevious}
        handleMarkAsComplete={this.handleMarkAsComplete}
        handleNext={this.handleNext}
        handleOpencongratulationsModel = {this.handleOpencongratulationsModel}
      />
    </Box>
  );
  // Customizable Area End


  render() {
    // Customizable Area Start
    const { value, lessons_page_sidebar } = this.state;
    return (

      <Grid container>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <div className="root" style={{height:"calc(100vh - 105px)" , overflow:"auto"}}>
              <Box>
                <Box className="sidebar_toggle" data-test-id="sidebar_toggle"  onClick={this.handletoggleSidebar}><img src={image_hide} alt="image_hide" style={{ height: "30px" }} /><span
                  className={this.state.toggleSidebar ? "sidebar_hide" : "sidebar_show"}>Hide Menu</span>
                </Box>

                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={this.handleChange}
                  aria-label="Vertical tabs example"
                  className={this.state.toggleSidebar ? "sidebar_hide tabs" : "tabs sidebar_show"}
                >
                  {lessons_page_sidebar && lessons_page_sidebar.map((item: any, index: number) =>
                    <Tab
                      key={index}
                      className="tab-label"
                      label={
                        <React.Fragment>
                          <div className="tab-title">
                            <div className="tab-title  trimWords">
                              <TabIcon
                                lessonType={item?.attributes?.lesson_type}
                                type={item?.type}
                              />
                              {item.type === "lessons" ? `${item.attributes.lesson_name}` : `${item.attributes.quiz_name}`}
                            </div>
                            <div>
                              {(item.attributes.completed || item.attributes.passed) && <img src={image_complete_card} alt="" className="tab-img" />}
                            </div>
                          </div>
                          <div className="tab-details trimWords">
                            <span className="trimWords" >{item.attributes.description}</span>
                          </div>
                        </React.Fragment>
                      }
                      {...a11yProps(index)}
                    />
                  )}
                </Tabs>
              </Box>

              {lessons_page_sidebar && lessons_page_sidebar.map((item: any, index: any) => (
                <TabPanel value={value} index={index} key={index} className="tabpanelmin">
                  {value === index && (
                    <>
                      {item.type === "lessons" && item.attributes.lesson_type === "video" && (
                        <this.LessonvideoContent item={item} />
                      )}
                      {item.type === "lessons" && item.attributes.lesson_type === "document" && (
                        <this.LessondocumentContent item={item} />
                      )}
                      {item.type === "quizzes" && (
                        <this.CustomTabContent item={item} />
                      )}
                    </>
                  )}
                </TabPanel>
              ))}
            </div>
            {this.state.isLoading && (
              <Box data-test-id={"activityLoader"} className="loader-outer">
                <Box className="loader-inner">
                  <CircularProgress />
                </Box>
              </Box>
            )}
          </Wrapper>

         { <CongratulationsModal
            open={this.state.open}
            congratulationsData={this.state.congratulationsData}
            navigateToAssessmentTestScreen = {this.navigateToAssessmentTestScreen}
          />}
        </ThemeProvider >
      </Grid >
    )
    // Customizable Area End
  }
}

// Customizable Area Start


export const DialogWrapper = styled(Box)({
  "&.congratulationsModal": {
    // backgroundColor: '#111',
    background: "red",
    "& .MuiDialog-paper": {
      backgroundIamge: 'url(image_bg)',
      congImg: {

      }
    }
  }
})

const webStyle = {
  inner: {
    position: "relative",
    border: "2px solid rgb(227, 227, 227)",
    borderRadius: "0 0 6px 6px",
    padding: "12px",
    display: "flex",
    gap: "12px",
    flexWrap: "wrap",
  },
  question: {
    background: "linear-gradient(60deg, rgb(172, 172, 172), rgb(238, 238, 238), rgb(172, 172, 172))",
    padding: "12px",
    borderRadius: "8px",
    boxShadow: "5px 5px 15px rgb(52, 52, 52)",
    fontSize: "1.2em",
    display: "flex",
    justifyContent: " space-between",
  },
  options: {

    gap: "12px",
  },
  option: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },

  descripInput: {
    width: "400px",
    minHeight: "40px",
    borderRadius: "8px",
    fontSize: "1.2em",
  },
  count: {
    position: "relative",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    background: " rgb(109, 109, 109)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}

export const Wrapper = styled(Box)({
  width: "100%",
  backgroundColor: "rgb(238, 238, 238)",
  height: "100%",
  // position: "relative",
  boxSizing: "border-box",
  borderLeft: '1px solid #ddd',
  borderTop: '1px solid #ddd',
  "& .courseCard": {
    width: "100%",
  },
  "& .subHeadingText": {
    lineHeight: "22px",
    cursor: "pointer",
    fontFamily: "ClotherRegular",
    color: '#18181E',
    fontSize: "14px",
  },
  "& .headingWrapper": {
    display: "flex",
    fontSize: '14px'
  },
  "& .trimWords": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // whiteSpace: "break-spaces",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 2, // Limit to 2 lines
    maxWidth: "100%",
    textTransform: 'capitalize',
    fontFamily: "ClotherRegular",
  },
  "& .activeHeadingText": {
    lineHeight: "22px",
    color: "#8735E1",
    fontSize: "14px",
    fontFamily: "ClotherBold",
    cursor: "pointer",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  "& .learningSubHeadingText": {
    color: 'gray',
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "21px",
    fontFamily: "ClotherRegular",
  },
  "& .loader-outer": {
    position: "absolute",
    left: 0,
    bottom: 0,
    minHeight: "calc(100vw - 460px)",
    top: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: "rgb(0, 0, 0, .4)",
},
"& .loader-inner": {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "40%",
    left: "50%",
},

  "& .mainHeading": {
    fontWeight: 700,
    margin: "0 0 10px !important",
    color: "#2B2A29",
    fontSize: "26px",
    fontStyle: "normal",
    fontFamily: "ClotherBold",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '25px'
  },
  "& .cardMainHeading": {
    fontSize: "16px",
    fontFamily: "ClotherBold",
  },
  "& .mainBox": {
    backgroundColor: 'white',
    maxWidth: "100%",
    padding: "16px"
  },
  "& .courseCardStyle": {
    // cursor: "pointer",
    background: "#fff",
    borderRadius: "8px",
    padding: "30px",
    marginBottom: "12px",
    boxShadow: 'none',
    '& .courseCard': {
      padding: '0px',
    },
    "& .MuiCardMedia-img": {

      flex: "1 0 25%",
      maxWidth: "25%",
      borderRadius: "8px",
      height: "230px"
    }
  },
  "& .resume-btn": {
    color: "#8735E1",
    fontWeight: 700,
    fontSize: "20px",
    borderRadius: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minHeight: "46px",
    border: "1px solid #8735E1",
    textTransform: "capitalize",
    // fontFamily: 'ClotherRegular',
  },
  
  "& .resume-btn1": {
    background: "#8735E1",
    color: "#fff",
  },
  "&.sidebar_show": {
    width: '250px',
    transition: '1s ease-out',
  },
  "&.sidebar_hide": {
    width: '0px',
    visibility: 'hidden',
    transition: '1s ease-out',
  },
  "& .sidebar_toggle": {
    padding: "12px 22px",
    display: 'flex',
    alignItems: 'center',
    fontSize: "16px",
    gap: '5px',
    color: '#8735E1'
  },
  "& .sidebar_hide": {
    width: '0px',
    visibility: 'hidden',
    transition: '0.5s ease-out',
  },
  "& .marginBottom":{
    marginBottom: '20px',
  },
  "& .root": {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  "& .tabs": {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    background: '#F8F8F8',
    // maxWidth: '250px',
    transition: '1s ease-out',
    "& .MuiTabs-indicator": {
      backgroundColor: '#8735E1',
      width: '3px',
    },
    "&.sidebar_show": {
      width: '250px',
      transition: '1s ease-out',
    },
    "&.sidebar_hide": {
      width: '0px',
      visibility: 'hidden',
      transition: '1s ease-out',
    },
  },
  "& .tabpanelmin": {
    width: '100%',
    backgroundColor: '#F8F8F8',
    height: '100vh',
  },
  "& .page-wrapper-container": {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  "& .tab-label": {
    padding: "16px 10px",
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&.MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#fff",
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,

    },
  },
  "& .tab-title": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "16px",
    fontWeight: 600,
    color: '#2B2A29',
    justifyContent: 'flex-start',
    width: '100%',
    textTransform: 'capitalize'
  },
  "& .tab-img": {
    width: '20px',
    height: '20px'
  },
  "& .tab-details": {
    fontSize: "14px",
    color: '#828282',
    width: '100%'

  },
  "& .lesson-detail": {
    background: '#F8F8F8',
    width: '100%',
    padding: '30px'
  },
  "& .course-video": {
    border: '1px solid #DCDCDC',
    borderRadius: '8px',
    marginBottom: '18px',
    position: 'relative',
    "& > div ": {
      borderRadius: '8px',
    },
    "&::before": {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#00000066',
    }
  },
  '& .cardContentMainBox': {
    // maxHeight: '200px',
    // overflowY: 'auto',
  },
  "& .quiz-radio-option-checked": {
    width: '100%',
    padding: '15px',
    border: '1px solid #8735E1',
    borderRadius: '8px',
    fontSize: '16px',
    color: '#A1A1A1',
    marginBottom: '15px',
    boxSizing: 'border-box',
    backgroundColor: '#FAF3FD',
  },
  "& .cardDescription": {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#828282',
    letterSpacing: '-0.41px',
    fontFamily: 'ClotherRegular',
  },
  "& .resume-btn-wrapper": {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    padding: '30px 30px 30px',
    backgroundColor: '#F8F8F8',
    marginTop: 'auto',
  },
  "& .page-wrapper": {
    padding: '30px',
    backgroundColor: '#F8F8F8',
    '& .quiz-card': {
      backgroundColor: '#fff',
      padding: '30px',
      borderRadius: '8px',
      "& .quiz-card-img": {
        backgroundColor: '#F8F8F8',
        borderRadius: '8px',
        height: '100%',
        marginBottom: '30px',
        "& .quiz-img": {
          width: '100%',
          height: 'calc(100% - 60px)',
        },
        "& .Quiz_options": {
          padding: '10px',
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: 'bold',
        }
      },
      "& .quiz-radio-option": {
        width: '100%',
        padding: '15px',
        border: '1px solid #DCDCDC',
        borderRadius: '8px',
        fontSize: '16px',
        color: '#A1A1A1',
        marginBottom: '15px',
        boxSizing: 'border-box',
        "& .optionlabel": {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',

        },
        "& .inputRedio": {
          width: '20px',
          height: '20px',
          "&:checked": {
            backgroundColor: '#8735E1'
          }
        },
        "& .selected": {
          bordercolor: '#8735E1',
          backgroundColor: '#FAF3FD'
        }

      },
      "& .questiontext": {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#111',
        marginBottom: '10px'
      }
    },
  },

  "& .resume-parent": {
    display: 'flex',
    gap: '10px'
  },
  "& .buttonStyle2Disabled": {
    backgroundColor: "#FFFFFF",
    color: "#DCDCDC",
    fontWeight: 700,
    fontSize: "18px",
    borderRadius: "8px",
    width: "225px",
    border: "1px solid #e4e4ee",
    textTransform: "capitalize",
  },
  '& .tab-title-chlid':{
    alignItems: 'start',
    lineHeight: '1.5'
  },
  '& .tab_details_trimWords_chlid':{
    fontSize: 'small',
    color: 'gray'
  }
});
// Customizable Area End
