import React from "react";
import {
    Box,
    Typography,
    Grid,
    Button,
    Paper,
    Avatar,
} from "@material-ui/core";
// Customizable Area Start
import FollowersController, { FollowersData, Props, configJSON } from "./FollowersController";
import Loader from "../../../components/src/Loader.web";
import { Pagination } from "@material-ui/lab";
import { Check, NavigateNext } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';

export default class Help extends FollowersController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Grid container style={webStyles.mainContainer as React.CSSProperties}>
                <Grid item lg={12} sm={12}>
                    <Box style={webStyles.headerBox}>
                        <span data-test-id="account-text" style={webStyles.accountText}>{configJSON.ProfileText}</span>
                        <NavigateNext />
                        <span data-test-id="account-text" style={webStyles.currentPage}>{configJSON.FollowersText}</span>
                    </Box>
                    <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.FollowersText} ({this.state.totalCount})</Typography>
                    <Grid container style={webStyles.mainGrid as React.CSSProperties}>
                        <Grid item lg={12} sm={12}>
                            {this.state.followers.map((FollowData: FollowersData) => {
                                return (
                                    <Grid key={FollowData.id} item lg={12} md={12} sm={12} container style={webStyles.paperContainer} alignItems="center" justifyContent="space-between">
                                        <Typography style={webStyles.Nameflex as React.CSSProperties}><Avatar src={FollowData.attributes.account.data.attributes.profile_image} />
                                            <span style={webStyles.userName as React.CSSProperties}>{FollowData.attributes.account.data.attributes.full_name}</span></Typography>
                                        {!FollowData.attributes.you_also_following_to_him ?
                                            <Button data-test-id="follow-btn" style={webStyles.followBtn as React.CSSProperties} variant="outlined"
                                                onClick={() => this.followerUser(FollowData)}
                                            >
                                                <AddIcon style={webStyles.AddBtn}></AddIcon>
                                                {configJSON.FollowBtnText}
                                            </Button>
                                            :
                                            <Button style={webStyles.followingBtn as React.CSSProperties} variant="contained" onClick={() => this.followerUser(FollowData)}>
                                                <Check style={webStyles.checkBtn}></Check>
                                                {configJSON.FollowingBtnText}
                                            </Button>
                                        }
                                    </Grid>
                                )
                            })}
                        </Grid>
                        {(!this.state.loader && this.state.followers.length === 0) &&
                            <Typography style={webStyles.noTextData}>{configJSON.NoDataText}</Typography>
                        }
                        <Grid container justifyContent="flex-end" alignItems="flex-end">
                            <Pagination
                                count={Math.ceil(this.state.totalCount / 5)}
                                page={this.state.currentPage}
                                variant="outlined"
                                style={webStyles.pagination}
                                onChange={this.handlePageChange}
                                data-test-id="handlePageChange"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Loader loading={this.state.loader} data-test-id="loader" />
            </Grid>
        );
    }
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
    pagination: {
        margin: "20px"
    },
    noTextData: {
        height: '50vh',
        width: "100%",
        fontFamily: 'clotherBold',
        fontSize: '20px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
    },
    checkBtn: {
        height: '18px',
        fill: "#FFFFFF"
    },
    AddBtn: {
        height: '18px',
        fill: "#8735E1"
    },
    Nameflex: {
        display: 'flex'
    },
    followingBtn: {
        height: '30px',
        padding: '14px 5px 14px 0px',
        borderRadius: '8px',
        border: '1px solid #8735E1',
        color: '#FFFFFF',
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: 'capitalize',
        background: '#8735E1',
    },
    followBtn: {
        height: '30px',
        padding: '14px 19px 14px 11px',
        border: '1px solid #8735E1',
        borderRadius: '8px',
        background: '#FFFFFF',
        color: '#8735E1',
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: 'capitalize'
    },
    userName: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#2B2A29',
        paddingLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '12px',
    },
    paperContainer: {
        padding: '20px 30px'
    },
    currentPage: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#8735E1'
    },
    mainGrid: {
        width: '100%',
        background: "#FFFFFF",
        minHeight: "50vh"
    },
    mainContainer: {
        background: 'rgb(237, 238, 238)',
        padding: '30px',
    },
    headerBox: {
        fontSize: '0.875rem',
        color: '#A1A1A1',
        display: 'flex',
        gap: '0.3rem',
        alignItems: 'center',
        marginBottom: '1.25rem'
    },
    accountText: {
        fontFamily: 'ClotherRegular',
        fontSize: "14px",
        lineHeight: "22px",
        color: '#A1A1A1',
    },
    accoundHeading: {
        fontFamily: "ClotherBold",
        fontSize: '24px',
        lineHeight: "24px",
        paddingBottom: '25px'
    },
};

// Customizable Area End

