import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");
export interface ProfileData {
  attributes: {
    full_name: string;
    function_name: string;
    work_location: string;
    date_of_birth: string;
    role_name: string;
    employee_id: string;
    profile_image: string;
  }
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel?: any;
  logoutClear?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  type: string;
  token: string;
  ProfileData: ProfileData;
  isLoading: boolean;
  currentPage: number,
  userId: number;
  totalCount: number;
  GoalsRank: string[];
  riskLevelRange: string[]
  // Customizable Area End
}
interface SS { }

export default class RiskCardProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileDetailsAPICallID: string = "";
  getRiskDetailsAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      token: "",
      ProfileData: {
        attributes: {
          full_name: "",
          function_name: "",
          work_location: "",
          date_of_birth: "",
          role_name: "",
          employee_id: "",
          profile_image: "",
        }
      },
      isLoading: false,
      currentPage: 1,
      userId: 0,
      totalCount: 0,
      GoalsRank: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
      riskLevelRange: ['1', '2', '3'],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    this.setState({ userId: SignUpID })
    this.getRiskDetailsAPI();
    this.getProfileDetails()
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getProfileDetailsAPICallID) {
          if (webResponseJson.data) {
            this.setState({
              isLoading: false,
              ProfileData: webResponseJson.data,
            });
          } else {
            this.setEmptyData()
            this.setState({ isLoading: false });
          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  setEmptyData = () => {
    this.setState({
      ProfileData: {
        attributes: {
          full_name: "",
          function_name: "",
          work_location: "",
          date_of_birth: "",
          role_name: "",
          employee_id: "",
          profile_image: "",
        }
      }
    })
  }

  getRiskDetailsAPI = async () => {
    let token = await getStorageData("authToken", false);
    this.setState({ isLoading: true })
    const headers = {
      token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RiskDetailsAPIEndPoint}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getRiskDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  getProfileDetails = async () => {
    let token = await getStorageData("authToken", false);
    this.setState({ isLoading: true })
    const headers = {
      token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountDetailsAPIEndPoint}${this.state.userId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getProfileDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }
  // Customizable Area End
}
