export const calender = require("../assets/calender.png");
export const lessonImage = require("../assets/lessonImage.png");
export const image_doc = require("../assets/image_doc_card.svg");
export const image_play_card = require("../assets/image_play_card.svg");
export const image_complete_card = require("../assets/image_complete_card.svg");
export const image_Vector = require('../assets/image_Vector.svg')
export const congurlaction = require('../assets/congurlaction.png');
export const image_bg = require('../assets/image_bg 1.png'); 
export const vector = require("../assets/image_complete_card.svg");
export const image_hide = require("../assets/image_hide_menu.svg");

