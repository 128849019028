export const logo = require("../assets/new-logo.png");
export const imgh = require("../assets/Home_Icon.png");
export const img1 = require("../assets/image_Learning.png");
export const img2 = require("../assets/view_Vector.png");
export const img3 = require("../assets/group_award.png");
export const img4 = require("../assets/image_Goals.png");
export const img5 = require("../assets/image_My Profile.png");
export const img6 = require("../assets/image_Survey.png");
export const img7 = require("../assets/image_Reports.png");
export const imgicon1 = require("../assets/image_profile.png");
export const imgicon2 = require("../assets/image_profile1.png");
export const imgicon3 = require("../assets/image_profile2.png");
export const imgfull = require("../assets/image_Rectangle.png");
export const Unlike = require("../assets/image_Like.png");
export const like = require("../assets/Vector.png");
export const comment = require("../assets/image_comment.png");
export const share = require("../assets/image_Share.png");
export const location = require("../assets/location.png");
export const attachment1 = require("../assets/image_attachment.png");
export const attachment2 = require("../assets/image_Image.png");
export const time = require("../assets/image_Time.png");
export const userimg = require("../assets/image_5.png");
export const lang = require("../assets/group_language.png");
export const logout = require("../assets/logout.png");
export const upload = require("../assets/upload.svg");
export const mic = require("../assets/mic.png");
export const posts = require("../assets/posts.png");
export const iconPlus = require("../assets/icon-plus.png");
export const iconTick = require("../assets/icon-tick.png");
export const avatar = require("../assets/avatar.jpg");
export const help = require("../assets/help.png");
export const postIcon = require("../assets/post-icon-stub.png");
export const postIconVar = require("../assets/post-icon-stub-var.png");
export const avatarSample = require("../assets/avatar-sample.jpeg");
export const cardGroup = require("../assets/card-group.png");
export const heartHandsGrad = require("../assets/heart-hands-grad.png");
export const arrowLeft = require("../assets/arrow-left.svg");
export const iconSafety = require("../assets/icon-safety.png");
export const arrowRightMobile = require("../assets/arrow-right-mobile.svg");
export const tabIconUser = require("../assets/tab-icon-user.png");
export const tabIconBag = require("../assets/tab-icon-tab-bag.png");
export const tabIconPhone = require("../assets/tab-icon-tab-phone.png");
export const editButon = require("../assets/edit-button.png");
export const mypost = require("../assets/Mypost.png");
export const account = require("../assets/Account.png");
export const grouplogout = require("../assets/Grouplogout.png");
export const cancelicon = require("../assets/crossicon.png");
export const languageIcon = require("../assets/button_language.png");
export const notificationIcon = require("../assets/button_notification.png");
export const heartHands = require("../assets/heart-hands.png");
export const star = require("../assets/star.png");
export const HelthIcon = require("../assets/group_1111.png")
export const HomeIcon = require("../assets/group_1.png")
export const RoadIcon = require("../assets/group_intersection+3.png")
export const selectLanguage = require("../assets/group_2.png")
export const safetyIcon = require("../assets/group_intersection+3112.png")
export const EnvironmentIcon = require("../assets/group22222.png")
export const InfoIcon = require("../assets/image_info.png")
export const JustStartIcon = require("../assets/image_Just+started.png")
export const OnTrackIcon = require("../assets/image_On+Track.png")
export const ExceedingIcon = require("../assets/image_Exceeding.png")
export const congrats = require("../assets/congrats.png")
export const spots = require("../assets/spots-bg.png")
export const copyIcon = require("../assets/copyIcon.png")
export const notificationsIcon = require("../assets/notifications-icon.png")
export const ActionTrackerIcon = require("../assets/group_survey.png")
export const imageLogo = require("../assets/image_Logo.png")
export const micStart = require("../assets/mic_Start.png")
export const searchDefaultImage = require("../assets/searchDefaultImage.png")
export const dummyImage = require("../assets/DummyImage.png")
export const SuccessImage = require("../assets/success.svg")
export const checkImg = require("../assets/view_mark.png")
export const addImg = require("../assets/plusIcon.png")
export const refreshImg = require("../assets/refresh.png")

