import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config.js");

export interface CommentData {
  id: number,
  attributes: {
    comment: string,
    created_at: string,
    account: {
      profile_image: string,
      full_name: string
    }
  }
}

export interface ProfileData {
  attributes: {
    activated: boolean,
    email: string,
    official_email: string,
    profile_image: string,
    full_phone_number: string,
    location_name: string,
    posts_count: number,
    followers: number,
    followings: number,
    role_name: string,
    full_name: string,
    country_code: number,
    posts: PostData[],
  }
}

export interface PostData {
  attributes: {
    openCommentsList: boolean;
    likes_count: number,
    comments_count: number,
    id: number,
    description: string,
    account_id: number,
    employee_code: null | number,
    profile_pic: string,
    user_name: string,
    image_url: string,
    work_location: string,
    location: string,
    created_at: string,
    did_you_like: boolean,
  }
}

export interface ContactDetailsData {
  work_email: string,
  country_code: string,
  contact_number: string,
  location: string,
  personal_email: string
}

export interface TagsData {
  attributes: {
    id: string,
    name: string
  }
  id: string,
  type: string,
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  type: string;
  token: string;
  editProfileData: ProfileData | null;
  isLoading: boolean;
  currentPage: number,
  userId: number;
  totalCount: number;
  is_following_by_you: boolean;
  postData: PostData[];
  isSameUser: boolean;
  loading: boolean;
  contactDetails: ContactDetailsData | null;
  otherUserId: number,
  likeCommentLoader: boolean,
  comments: CommentData[];
  commentsloading: boolean;
  totalComment: number;
  anchorElReportComment: null | HTMLElement,
  reportCommentOption: boolean;
  currentPostId: number;
  currentCommentPage: number;
  reportCommentModal: boolean;
  postIsReported: boolean;
  reportdescription: string;
  selectedTags: number[];
  reportTags: TagsData[];
  isDescriptionErr: boolean;
  currentCommentId: number;
  reportedModalBodyText: string;
  reportedModalHeading: string;
  // Customizable Area End
}
interface SS { }

export default class OtherProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountDetailsAPICallID: string = "";
  FollowUserAPICallID: string = "";
  ContactDetailsAPICallID: string = "";
  LikePostAPICallID: string = "";
  GetCommentsAPICallID: string = "";
  apiGetReportsTags: string = "";
  reportCommentsAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      token: "",
      isLoading: false,
      loading: false,
      editProfileData: null,
      currentPage: 1,
      userId: 0,
      totalCount: 0,
      is_following_by_you: false,
      postData: [],
      isSameUser: false,
      contactDetails: null,
      otherUserId: 0,
      likeCommentLoader: false,
      comments: [],
      commentsloading: false,
      totalComment: 0,
      anchorElReportComment: null,
      reportCommentOption: false,
      currentPostId: 0,
      currentCommentPage: 0,
      reportCommentModal: false,
      postIsReported: false,
      reportdescription: '',
      selectedTags: [],
      reportTags: [],
      isDescriptionErr: false,
      currentCommentId: 0,
      reportedModalBodyText: configJSON.ReportedSubHeaderText,
      reportedModalHeading: configJSON.CommentReportedText,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData("authToken");
    let otherUserId = JSON.parse(await getStorageData("otherUserId"));
    this.setState({ otherUserId: otherUserId })
    let currentUserId = JSON.parse(await getStorageData("SignUpID"));
    this.setState({ userId: otherUserId, token })
    if (otherUserId === parseInt(currentUserId)) {
      this.setState({ isSameUser: true })
    } else {
      this.setState({ isSameUser: false })
    }
    this.setState({ isLoading: true })
    this.getAccountDetailsAPI(otherUserId, 1);
    this.getContactDetails(otherUserId)
    this.getReportsTags();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getAccountDetailsAPICallID) {
          if (webResponseJson.other_user_profile) {
            this.setState({ isLoading: false, likeCommentLoader: false })
            this.setState({
              is_following_by_you: webResponseJson.is_following_by_you,
              editProfileData: webResponseJson.other_user_profile.data,
              postData: webResponseJson.posts.data,
              totalCount: webResponseJson.posts.meta.pagination.total_count,
              isLoading: false,
            });
          } else {
            this.setState({ isLoading: false, postData: [], editProfileData: null });
          }
        }
        this.setLikePostRes(webApiRequestCallId, webResponseJson)
        this.APICallsRes(webApiRequestCallId, webResponseJson)
        this.ContactDetailsAPICallsRes(webApiRequestCallId, webResponseJson)
        this.viewCommentsRes(webApiRequestCallId, webResponseJson)
        this.getReportTagsData(webApiRequestCallId, webResponseJson)
        this.CommentReportApiResponse(webApiRequestCallId, webResponseJson)
      } else {
        this.setState({
          isLoading: false,
          commentsloading: false,
          currentCommentPage: 0,
          anchorElReportComment: null,
          reportCommentOption: false,
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  setLikePostRes = (webApiRequestCallId: string, webResponseJson: { data: { id: number }; message: string; errors: null; }) => {
    if (webApiRequestCallId === this.LikePostAPICallID) {
      if (webResponseJson.data || webResponseJson.message || webResponseJson.errors === null) {
        this.setState({ likeCommentLoader: false, currentPostId: 0 });
        this.getAccountDetailsAPI(this.state.otherUserId, 1)
      }
    }
  }

  viewCommentsRes = (apiRequestCallId: string, responseJson: {
    total_comment: number; data: CommentData[];
  }) => {
    if (apiRequestCallId === this.GetCommentsAPICallID) {
      if (responseJson.data) {
        let commentsData = this.state.comments.concat(responseJson.data);
        this.setState({ comments: commentsData, totalComment: responseJson.total_comment, commentsloading: false });
      }
    }
  }

  APICallsRes = (webApiRequestCallId: string, webResponseJson: { message: string; data: { id: number, type: string, }; }) => {
    if (webApiRequestCallId === this.FollowUserAPICallID) {
      this.setFollowersApiRes(webResponseJson)
    }
  }

  ContactDetailsAPICallsRes = (webApiRequestCallId: string, webResponseJson: { message: string; data: ContactDetailsData }) => {
    if (webApiRequestCallId === this.ContactDetailsAPICallID) {
      this.setContactDetails(webResponseJson)
    }
  }

  setContactDetails = (webResponseJson: { data: ContactDetailsData; }) => {
    this.setState({ contactDetails: webResponseJson.data })
  }
  setFollowersApiRes = (webResponseJson: { message: string; data: { id: number, type: string, }; }) => {
    if (webResponseJson.message === "You can not follow yourself") {
      this.setState({ isLoading: false });
    } else if (webResponseJson.data) {
      this.setState({ isLoading: false });
      this.getAccountDetailsAPI(this.state.userId, 1)
    }
  }

  getReportTagsData = (apiRequestCallId: string, responseJson: { data: TagsData[]; }) => {
    if (apiRequestCallId === this.apiGetReportsTags) {
      console.log("responseJson.data: ", responseJson.data)
      this.setState({ reportTags: responseJson.data });
    }
  };

  CommentReportApiResponse = (webApiRequestCallId: string, webResponseJson: { data: { id: number }; message: string }) => {
    if (webApiRequestCallId === this.reportCommentsAPICallID) {
      if (webResponseJson?.message) {
        this.setState({ reportedModalBodyText: webResponseJson?.message, reportedModalHeading: 'Failed to Report Comment' });
      }
      this.setState({
        commentsloading: false,
        anchorElReportComment: null,
        reportCommentOption: false,
        isLoading: false,
        postIsReported: true,
      });

    }
  }

  getAccountDetailsAPI = async (otherUserId: number, currentpage: number) => {
    const headers = {
      token: this.state.token
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetailsAPICallID = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_followers/follow/other_user_profile?account_id=${otherUserId}&page=${currentpage}&per_page=4`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage });
    this.getAccountDetailsAPI(this.state.userId, newPage);
  };

  followUser = async () => {
    this.setState({ isLoading: true })
    let token = await getStorageData("authToken", false);
    const headers = {
      token: token,
      "Content-Type": configJSON.getEducationContentType
    };

    const requestData = {
      "follow_user_id": this.state.userId
    }

    const getFollowersMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FollowUserAPICallID = getFollowersMsg.messageId;

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FollowUserAPIEndPoint
    );

    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    getFollowersMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    runEngine.sendMessage(getFollowersMsg.id, getFollowersMsg);
  }

  getContactDetails = async (otherUserId: number) => {
    this.setState({ isLoading: true })
    const headers = {
      token: this.state.token
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ContactDetailsAPICallID = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/profile_privacy?id=${otherUserId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  proceedToLike = (data: { did_you_like: boolean; id: number; }) => {
    this.setState({ likeCommentLoader: true, currentPostId: data.id });
    if (data.did_you_like) {
      this.unLikePost(data.id)
    } else {
      this.likePost(data.id)
    }
  }

  unLikePost = (postId: number) => {
    const apiHeader = {
      "Content-Type": configJSON.getEducationContentType,
      token: this.state.token,
    }
    let formdata = {
      data: {
        type: "unlike",
        attributes: {
          post_id: postId
        }
      }
    }
    const likePostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LikePostApiEndPoint
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    this.LikePostAPICallID = likePostRequestMsg.messageId;
    runEngine.sendMessage(likePostRequestMsg.id, likePostRequestMsg);
  }

  likePost = async (postId: number) => {
    let formdata = {
      "data": {
        type: "like",
        attributes: {
          post_id: postId
        }
      }
    }
    const header = {
      "Content-Type": configJSON.getEducationContentType,
      token: this.state.token,
    }
    const likePostRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.LikePostApiEndPoint
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formdata)
    );
    likePostRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    this.LikePostAPICallID = likePostRequestMsg.messageId;
    runEngine.sendMessage(likePostRequestMsg.id, likePostRequestMsg);
  }

  showComments = (id: number | undefined, postIndex: number) => {
    this.state.postData.forEach((data, index) => {
      if (index !== postIndex) {
        data.attributes.openCommentsList = false
        this.setState({
          postData: this.state.postData
        })
      } else {
        data.attributes.openCommentsList = !data.attributes.openCommentsList;
        this.setState({
          postData: this.state.postData
        })
      }
    })
    this.setState({ comments: [], currentCommentPage: 0 }, () => {
      this.getComment(id)
    })
  }

  getComment = (id: number | undefined) => {
    this.setState({
      commentsloading: true,
      totalComment: 0,
      currentCommentPage: this.state.currentCommentPage + 1,
    })
    const headers = {
      "Content-Type": configJSON.getEducationContentType,
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_comments/comments?commentable_id=${id}&per_page=${5}&page=${this.state.currentCommentPage + 1}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.GetCommentsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  timeSince = (date: string) => {
    let createdAt = date;
    let currentTime = moment();
    let createdTime = moment(createdAt);
    let diff = currentTime.diff(createdTime, 'seconds');

    if (diff < 60) {
      return diff + ' seconds ago'
    } else if (diff < 3600) {
      return Math.floor(diff / 60) + ' minutes ago'
    } else if (diff < 86400) {
      return Math.floor(diff / 3600) + ' hours ago'
    } else if (diff < 2592000) {
      return Math.floor(diff / 86400) + ' days ago'
    } else if (diff < 31536000) {
      return Math.floor(diff / 2592000) + ' months ago'
    } else {
      return Math.floor(diff / 31536000) + ' years ago'
    }
  }

  showReportComment = (event: any, id: number) => {
    this.setState({
      reportCommentOption: !this.state.reportCommentOption,
      anchorElReportComment: event.currentTarget,
      currentCommentId: id,
    })
  }

  openReportCommnetModal = () => {
    this.setState({ reportCommentModal: true });
  }

  closeReportModal = () => {
    this.setState({
      reportCommentModal: false,
      postIsReported: false,
      reportdescription: '',
      selectedTags: [],
    })
  }

  getTagColor = (id: number) => {
    return this.state.selectedTags.includes(id) ? true : false;
  }

  addSelectedTags = (id: number) => {
    this.setState({ isDescriptionErr: false });
    this.setState((prevState) => {
      const isSelected = [...prevState.selectedTags];
      const selectedTags = isSelected.indexOf(id);
      if (selectedTags === -1) {
        isSelected.push(id);
      } else {
        isSelected.splice(selectedTags, 1);
      }
      return { selectedTags: isSelected };
    });
  }

  handledescriptionChange = (event: { target: { value: string; }; }) => {
    this.setState({
      isDescriptionErr: false,
      reportdescription: event?.target.value,
    })
  }

  handleReportApiCall = () => {
    if (this.state.reportCommentModal) {
      this.reportPostComment();
    }
  }

  getReportsTags = () => {
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };
    this.setState({ isLoading: true });

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetReportsTags = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tagsAPIEndPoint
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  reportPostComment = () => {
    if (this.state.reportdescription === "" && this.state.selectedTags.length === 0) {
      this.setState({ isDescriptionErr: true })
      return false
    }
    this.setState({ commentsloading: true })
    let reportData = {
      "comment_id": this.state.currentCommentId,
      "description": this.state.reportdescription,
      "report_tag_ids": this.state.selectedTags,
    }
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.notificationsContentType,
    };
    const getReportRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportCommentsAPIEndPoint}`
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reportData)
    );
    getReportRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.reportCommentsAPICallID = getReportRequestMsg.messageId;
    runEngine.sendMessage(getReportRequestMsg.id, getReportRequestMsg);
  }
  // Customizable Area End
}
